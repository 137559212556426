<template>
    <v-container>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Relatorio Insumos Totais</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" small dark fab v-bind="attrs" v-on="on">
                            <v-icon>mdi-hammer-wrench </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in toolItems" :key="index" @click="actionMenuTool(item.action)">
                            <v-list-item-title><v-icon class="mr-2"> {{ item.icon }} </v-icon>{{
                                item.title
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-spacer></v-spacer>

            </v-toolbar>
            <v-progress-linear v-if="loading_table" indeterminate></v-progress-linear>

            <div v-for="group in inputsPerGroup" :key="group.id">
                <div class="sub_title mt-3">{{ group[0].input_group.code + ' - ' + group[0].input_group.name }}</div>
                <v-simple-table dense>
                    <thead>
                        <tr>
                            <th class="text-left">
                                INSUMOS
                            </th>
                            <th class="text-center">
                                UNIDADE
                            </th>
                            <th class="text-right">
                                QUANTIDADE
                            </th>
                            <th class="text-right">
                                P UNIT. (R$)
                            </th>
                            <th class="text-right">
                                TOTAL
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="input in group" :key="input.id">
                            <td width="550">{{ input.input_group.code + '-' + input.full_code + " - " + input.name }}</td>
                            <td width="150" class="text-center">{{ input.unity }} </td>
                            <td width="150" class="text-right">{{ formatMoney(input.pivot.quantity) }} </td>
                            <td width="150" class="text-right"> {{ formatMoney(input.value_with_tax) }} </td>
                            <td width="150" class="text-right"> {{ formatMoney(input.sub_total) }} </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="text-right" style="background:#1976d2 ;color: white"></td>
                            <td class="text-right" style="background:#1976d2 ;color: white"></td>
                            <td class="text-right" style="background:#1976d2 ;color: white"></td>
                            <td class="text-right" style="background:#1976d2 ;color: white">TOTAL:</td>
                            <td  class="text-right" style="background:#1976d2; color: white"> {{ formatMoney(calcTotal(group)) }}</td>
                        </tr>
                    </tfoot>
                </v-simple-table>

            </div>
            <div class="sub_title mt-5 text-right ">
                <spam class="mr-3"> TOTAL: R$ {{ formatMoney(grandCalcTotal()) }}</spam>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import service from "../service";

export default {
    data: () => ({
        costId: null,
        inputsPerGroup: [],
        budgetId: null,
        loading_table: true,
        toolItems: [
            { title: "Exportar Excel", action: "export_excel", icon: "mdi-file-excel" },
        ],
    }),
    computed: {

    },
    watch: {

    },
    created() {
        this.costId = this.$route.params.cost_id;
        this.getInputTotal();
    },
    methods: {
        getInputTotal() {
            this.loading_table = true
            service
                .getInputTotal(this.costId)
                .then((result) => {
                    this.inputsPerGroup = result.data;
                })
                .catch()
                .finally(() => {
                    this.loading_table = false
                });
        },
        actionMenuTool(action) {
            switch (action) {
                case "export_excel":
                    service.ExportExcelInputsTotal(this.costId)
                        .then((response) => {

                            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                            var fileLink = document.createElement('a');

                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', 'relatorio_insumos_totais.xlsx');
                            document.body.appendChild(fileLink);
                            fileLink.click();

                        }).catch((response) => {
                            console.log(response)
                        })
                    break;
            }
        },

        calcTotal(inputs) {
            let total = 0
            total = inputs.reduce((acumulator, input) => {
                return acumulator + input.value_with_tax * input.pivot.quantity;
            }, 0);

            return total;
        },
        grandCalcTotal() {
            let total = 0
            this.inputsPerGroup.forEach(element => {
                total += element.reduce((acumulator, input) => {
                return acumulator + input.value_with_tax * input.pivot.quantity;
            }, 0);
            });


            return total;
        },

        formatMoney(number) {
            number = this.parseNumber(number);
            return number.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        parseNumber(number) {
            if (isNaN(number)) {
                return 0;
            }
            let result = parseFloat(number);
            return result;
        },

    },
};
</script>

<style scoped>
.sub_title {
    background: #1976d2;
    color: white;
    height: 32px;
    box-sizing: border-box;
    padding-top: 4px;
    padding-left: 4px;
}
</style>
